<template>
  <div class="allowed-brands">
    <h3>Список разрешенных брендов</h3>
    <a-list bordered :data-source="data">
      <a-list-item slot="renderItem" slot-scope="item">
        {{ item }}
      </a-list-item>
    </a-list>
  </div>
</template>

<script>
// TODO: CHANGE DATA TO STORE
export default {
  name: "AllowedBrandsList",
  data() {
    return {
      data: ["Land Rover", "Jaguar", "Audi", "Toyota", "Volkswagen"],
    }
  },
}
</script>

<style scoped></style>
